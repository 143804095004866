import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import MainTitle from "../shared/heroTitles";
import Paragraph from "../shared/paragraphs";
import { Link } from "gatsby";
import Table from "../shared/table";
import { useIntl } from "gatsby-plugin-react-intl";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 64px 0;
  margin: auto;
  height: 100%;
  gap: 30px;

  .wrapper {
    width: 100%;
  }
  .full {
    width: 100%;
  }

  table,
  td {
    border: 1px solid black;
    padding: 0;
  }
  .wrapper {
    width: 100%;
  }

  table,
  .responsive-table {
    width: 100%;
    overflow: auto;
  }

  td {
    vertical-align: top;
    padding: 8px;
    width: 100px;
    min-width: 0;
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 540px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const SubtitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 499px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const RulesBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 792px;
  gap: 40px;
  margin-bottom: 90px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const TermsTitles = styled.div`
  color: #111111;
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
  font-style: normal;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  word-break: break-word;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #111111;
  margin: 0px;
  padding: 0px;
`;

function Hero() {
  const intl = useIntl();
  const html = `${intl.formatMessage({
    id: "CookiesPolicy",
  })}`;
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          {html != "exist" && (
            <div
              className="full"
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            ></div>
          )}
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
